import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../app/api";
import { useTypedSelector } from "../hooks/useTypedSelector";
import useUrlQuery from "../hooks/useUrlQuery";
import { setQuoteEndPoints } from "../modules/quotes.slice";

export interface BrandsInterface {
  BRAND_ID: number;
  BRAND_TITLE: string;
}

export interface ModelsInterface {
  MODEL_ID: number;
  MODEL_NAME: string;
}
export interface VariantsInterface {
  VERSION_ID: number;
  VARIANT_NAME: string;
}
interface RTOInterface {
  city_id: number;
  rto_id: number;
  rto_name: string;
  rto_number: string;
}
export interface BikeResponse {
  message: string;
  status: 200 | 500;
}
interface GetBrandsResponse extends BikeResponse {
  response: BrandsInterface[];
}

interface GetModelsResponse extends BikeResponse {
  response: ModelsInterface[];
}

interface GetVariantsResponse extends BikeResponse {
  response: VariantsInterface[];
}

interface GetRTOInterface extends BikeResponse {
  response: RTOInterface[];
}
interface GetEligibleProductsResponseSuccess {
  status: 200;
  response: {
    quote_id: string;
    quote_end_points: string[];
    eligible_products_count: number;
  };
  request: {
    vehicle_make: string;
    vehicle_model: string;
    vehicle_variant: string;
    vehicle_version_id: string;
    fuel_type: string;
    rto_number: string;
    rto_name: string;
    vehicle_registration_year: string;
    lob: "bike";
    policy_expired_before_90_days: "Y" | "N" | "O";
    is_claim_made_last_year: "Y" | "N";
    is_policy_expired: "Y" | "N";
    mobile_no: string;
    existing_exp_date: string;
    registration_date: string;
    manf_month: string;
    prev_ncb: number;
    prev_policy_type: "COMP" | "TP";
    NCB: number;
    policy_type: [
      {
        display_name: string;
        code: string;
        description: string;
      }
    ];
  };
}
export interface PreviousPolicyInterface {
  policy_status: "Y" | "N";
  claim_status: "Y" | "N";
  mobile_number: string;
  policy_expired_before_90_days: "Y" | "N" | "O";
}
const useGetBrandsQuery = () => {
  const { data, isLoading } = useQuery("brands", () =>
    api.post<GetBrandsResponse>("/GetBikeBrands")
  );
  return { brandsList: data?.data?.response || [], isBrandsLoading: isLoading };
};

const useGetModelQuery = () => {
  const { brand } = useTypedSelector(({ landing }) => landing.vehicleDetails);
  const { data, isLoading } = useQuery(["models", brand.id], () =>
    api.post<GetModelsResponse>("/GetBikeModels", {
      BRAND_ID: brand.id,
    })
  );
  return { modelsList: data?.data?.response || [], isModelsLoading: isLoading };
};

const useGetVariantQuery = () => {
  const { model } = useTypedSelector(({ landing }) => landing.vehicleDetails);
  const { data, isLoading } = useQuery(["variants", model.id], () =>
    api.post<GetVariantsResponse>("/GetBikeVariants", {
      MODEL_ID: model.id,
    })
  );
  return {
    variantsList: data?.data?.response || [],
    isVariantsLoading: isLoading,
  };
};
const useGetRTOQuery = () => {
  const { data, isLoading } = useQuery("rto", () =>
    api.post<GetRTOInterface>("/RtoMaster")
  );
  return {
    rtoList:
      data?.data?.response.map((item) => ({
        OPTION_KEY: item.rto_number,
        OPTION_VAL: `${item.rto_number} ${item.rto_name}`,
      })) || [],
    isRTOLoading: isLoading,
  };
};
const useGetEligibleProductsMutation = () => {
  const { model, variant, brand, rto, year } = useTypedSelector(
    (state) => state.landing.vehicleDetails
  );
  const { user } = useVerifyHash();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    (data: {
      is_policy_expired: "Y" | "N";
      is_claim_made_last_year: "Y" | "N";
      mobile_no: string;
      policy_expired_before_90_days: "Y" | "N" | "O";
    }) =>
      api.post<GetEligibleProductsResponseSuccess>("/GetBikeEligibleProducts", {
        // vehicle_registration_no: "mh02gt2201",
        vehicle_make: brand.title,
        vehicle_model: model.title,
        vehicle_variant: variant.title,
        vehicle_version_id: variant.id,
        fuel_type: "Petrol",
        rto_number: rto.number,
        rto_name: rto.name,
        vehicle_registration_year: year,
        lob: "bike",
        ...(user ? { role_id: user.ROLE_ID, user_id: user.USER_ID } : {}),
        ...data,
      }),
    {
      onSuccess: (data) => {
        if (data.status === 500) {
          toast.error("Server Error");
          return;
        }
        if (data.status === 200) {
          dispatch(setQuoteEndPoints(data.data.response.quote_end_points));
        }

        navigate({
          pathname: "/quotes",
          search: `?quote_id=${data.data.response.quote_id}`,
        });
      },
    }
  );
  return {
    getEligibleProducts: mutate,
  };
};
const useGetEligibleProductsQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const quoteId = getUrlQuery("quote_id");
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useQuery(
    ["eligibleProducts", quoteId],
    () =>
      api.get<GetEligibleProductsResponseSuccess>(
        "/GetBikeEligibleProductsById/" + quoteId
      ),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          dispatch(setQuoteEndPoints(data.data.response.quote_end_points));
        }
      },
      enabled: !!quoteId,
    }
  );
  return {
    eligibleProducts: data?.data?.response || {},
    isEligibleProductsLoading: isLoading,
    requestData: data?.data?.request,
    isSuccess: isSuccess,
  };
};
export const useFilterMutation = () => {
  const dispatch = useDispatch();
  const { setUrlQuery } = useUrlQuery();
  const { mutate, isLoading, data, isSuccess } = useMutation(
    (req: any) =>
      api.post<GetEligibleProductsResponseSuccess>(
        "/GetBikeEligibleProducts",
        req
      ),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          dispatch(setQuoteEndPoints(data.data.response.quote_end_points));
          setUrlQuery("quote_id", data.data.response.quote_id);
        }
      },
    }
  );
  return {
    updateFilter: mutate,
    isUpdatingFilter: isLoading,
    data: data?.data?.response || {},
    isSuccess: isSuccess,
    quote_id: data?.data?.response?.quote_id,
  };
};
const useVerifyHash = () => {
  const { removeUrlQuery, getUrlQuery } = useUrlQuery();
  const hash = getUrlQuery("hash");
  const location = useLocation();
  const { data, isLoading } = useQuery(
    ["auth", hash, location.pathname],
    () => api.post("/VerifyToken", { HASH: hash }),
    {
      onSuccess: (data) => {
        if (data?.data?.status === 200 && hash)
          localStorage.setItem("hash", hash);
        else {
          localStorage.removeItem("hash");
          removeUrlQuery("hash");
          if (process.env.REACT_APP_SERVER === "PRODUCTION") {
            window.open("https://account.vkover.com", "_self");
          }
        }
      },
      enabled: location.pathname === "/",
    }
  );
  return {
    isVerified: data?.data?.status === 200,
    isVerifying: isLoading,
    user: data?.data?.response,
  };
};
export {
  useGetBrandsQuery,
  useGetModelQuery,
  useGetVariantQuery,
  useGetRTOQuery,
  useGetEligibleProductsMutation,
  useGetEligibleProductsQuery,
  useVerifyHash,
};
